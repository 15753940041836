import React, { useEffect, useState } from 'react';
import GraphBar from './graphBar';
import GraphPie from './graphPie';
import GraphLine from './graphLine';
import Table1 from './table1';
import Table2 from './table2';
import Table3 from './table3';
import { faker } from '@faker-js/faker';
import './GraphPage.sass'
import { utils, read, writeFile } from 'xlsx';

import logoBePass from '../../assets/images/logo-bepass.png'
import logoHome from '../../images/home_logo.png'
import logoVisitor from '../../images/visitor_logo.png'

import { MdOutlineSimCardDownload } from "react-icons/md";


import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'

const GraphPage = () => {
  const name1 = faker.name.firstName();
  const name2 = faker.name.firstName();

  const date = new Date().toLocaleDateString();
  const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  const dateTime = `${date} ${time}`;


  const [reportUserList, setReportUserList] = useState<any[]>([])

  const getAllEntrances = () => {



    fetch(`${BASE.GRAPHY.url}${BASE.GRAPHY.getEntrnaces}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: CONFIG.API_KEY,
            'x-api-key': CONFIG.API_KEY,
        }
    })
        .then((resg) => resg.json())
        .then((response) => {
          setReportUserList(response.entrances[0]);

          setTimeout(() => {
            getAllEntrances()
          }, 30000);
        })
        .catch((error) => {
            console.log(error)
            setTimeout(() => {
              getAllEntrances()
            }, 30000);
        })
}


const downloadEntranceReport = () => {

  const reportDownload:any = []
  for(const entrance of reportUserList){

    const dateStr = entrance.checkInDate;
    const date = new Date(dateStr);

    // Subtract 3 hours (3 hours * 60 minutes/hour * 60 seconds/minute * 1000 milliseconds/second)
    date.setTime(date.getTime() - (6 * 60 * 60 * 1000));

    const result = date.toISOString();

    console.log(result, entrance.checkInDate)
    reportDownload.push({
      entrada: result,
      codigo: entrance.tokens[0].token,
      cliente: entrance.holderName,
      clienteDocumento: entrance.holderDocument
    })

  }

  const worksheet = utils.json_to_sheet(reportDownload);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'relatorio');
  writeFile(workbook, `relatorio_de_entradas.xlsx`);

}

useEffect(() => {
  getAllEntrances();

  // setInterval(
    // getAllEntrances
    // , 30000);

}, []);


  return (
    <div className='back street-graph'>
      <div className="row mt-1 graph-header" >
        <div className="logo-graph"><img src={logoBePass} /></div>
        <div className="col report-download-link" onClick={() => {downloadEntranceReport()}}>
          <MdOutlineSimCardDownload/> relatório de entradas
        </div>
        <div className="col d-flex justify-content-center align-items-center">
          <h2 className='text-light h4 game-title'>
            <img src={logoHome}/>
            Bahia
           <span>vs</span>
           Internacional
            <img src ={logoVisitor}/>
          </h2>
        </div>
        <div className="col d-flex justify-content-end align-items-center minha-data-maldita">
          <h2 className='text-light h4'>{dateTime}</h2>
        </div>
      </div>
      {/* <div>
        <GraphBar />
      </div> */}
      <div style={{ height: '550px'}} className='d-flex justify-content-around mt-4'>
       <GraphPie />
        <Table1/>
        <Table2/>
        <Table3/>
      </div>
      <div>
        <GraphLine/>
      </div>
    </div>
  );
};

export default GraphPage;



import React, { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
import './GraphPage.sass'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'

import { utils, read, writeFile } from 'xlsx';

import { MdOutlineSimCardDownload } from "react-icons/md";

const Table2 = () => {
    const [dadosAlertaCatracas, setDadosAlertaCatracas] = useState<{ problema: string; valor: number }[]>([]);

    const [supervisorData, setSupervisorData] = useState([])

    const [ocurrenciesData, setOcurrenciesData] = useState([])


    const getAllEntrances = () => {

      fetch(`${BASE.GRAPHY.url}${BASE.GRAPHY.getSupervisors}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              Authorization: CONFIG.API_KEY,
              'x-api-key': CONFIG.API_KEY,
          }
      })
          .then((resg) => resg.json())
          .then((response) => {
            setSupervisorData(response)
          })
          .catch((error) => {
              console.log(error)
          })
  }


  const getOcurrenciesCount = () => {

    fetch(`${BASE.GRAPHY.url}${BASE.GRAPHY.getOcurrencyCount}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: CONFIG.API_KEY,
            'x-api-key': CONFIG.API_KEY,
        }
    })
        .then((resg) => resg.json())
        .then((response) => {
          setOcurrenciesData(response)
        })
        .catch((error) => {
            console.log(error)
        })
}


const downloadEntranceReport = (reportUserList) => {

  const worksheet = utils.json_to_sheet(reportUserList);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'relatorio');
  writeFile(workbook, `relatorio_ocorrencias.xlsx`);

}

    useEffect(() => {
      const gerarDadosAlertaCatracas = () => {
        const tiposProblemas = ['Problema 1', 'Problema 2', 'Problema 3', 'Problema 4', 'Problema 5'];
  
        const novosDados = tiposProblemas.map((problema) => {
          const valor = faker.datatype.number({ min: 0, max: 20 }); 
          return { problema, valor };
        });
  
        setDadosAlertaCatracas(novosDados);
      };
      

      // getAllEntrances();
      // getOcurrenciesCount();
      // gerarDadosAlertaCatracas();

      // setInterval( () => {
        getAllEntrances();
        getOcurrenciesCount();
        gerarDadosAlertaCatracas();
      // }
        
        // , 30000);
    }, []);
  

    const mapper = (item) => {
      switch (item){
        case 'double_entrance':
          return 'Dupla entrada'
        case 'not_found':
          return 'Não encontrado'
        default:
          return 'Não encontrado'
      }
    }
    return (
      <div>
        <div className='text-center mb-1'>
          <h5 className="text-white bg-dark bg-gradient" style={{ width:'300px'}}>
            Supervisor

            <span className='internal-downloader' onClick={() => {downloadEntranceReport(supervisorData[0])}}>
              <MdOutlineSimCardDownload/>
            </span>
          </h5>
          <div className="back-gradiente">
            <h1 className="text-white">{supervisorData[1] || 0}</h1>
          </div>
        </div>  

      <h4 className='text-white mt-4 text-center h6'>
        Alerta de catracas
        <span className='internal-downloader' onClick={() => {downloadEntranceReport(ocurrenciesData)}}>
          <MdOutlineSimCardDownload/>
        </span>
      </h4>
      <table className='table table-bordered table-hover border-dark text-center' style={{ fontSize: '0.6rem' }}>
        <tbody>
          {ocurrenciesData.map((item:any, index) => (
            <tr key={index}>
              <td className='text-white border-dark' style={{ backgroundColor: 'rgba(87, 100, 92, 0.1)' }}>{mapper(item.ocurrency)}</td>
              <td className='text-white border-dark' style={{ backgroundColor: 'rgba(87, 100, 92, 0.1)' }}>{item.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    
    );
  };

export default Table2;
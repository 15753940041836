import React, { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'

interface DadosSetor {
  setor: string;
  pessoasEntraram: number;
  pessoasFaltam: number;
  total: number;
}



const Table1 = () => {
  const [dados, setDados] = useState<DadosSetor[]>([]);
  const [totalTickets, setTotalTickets] = useState<any>(0)


  const enumGate = (gateNumber:any) =>{

    switch (gateNumber) {
      case '1,2':
        return `${gateNumber}: 1,2`;
      case '7,8':
        return `${gateNumber}: Torcida Jovem / Jose de Alencar`;
      case '13':
        return `${gateNumber}: Cativa`;
      case '15':
        return `${gateNumber}: Cativa`;
      case '16':
        return `${gateNumber}: Camarote`;
      case '13,14 & 15':
        return `${gateNumber}: Cativa`;
      case '17':
        return `${gateNumber}: Cativa`;
      case '19':
        return `${gateNumber}: Visitante`;
      case '22':
        return `${gateNumber}: Geral Santista`;
      case '23':
        return `${gateNumber}: Camarote Superior`;
      case '24':
        return `${gateNumber}: Arquibancada Superior / Fundo Superior`;
      case '25':
        return `${gateNumber}: Familia`;
      case '26':
        return `${gateNumber}: Coberta`;
      default:
        return gateNumber
    }
  }

  const mapSectors = {
    "Lounge Leste Brax - Acesso 20(Oeste2) e Acesso 4(N1/N2)": "Camarote",
    "Visitante Superior - Acesso 6 (Portão Visitante)": "Visitante Superior",
    "Cadeira Sudeste Intermediário - Acesso 3 (Sul)": "Esquadrão Zone",
    "Esquadrãozone Arquibancada VIP": "Esquadrão Zone",
    "Camarotes Oeste - Acesso 4 (N1/N2) Acesso 20 (Oeste 2)": "Camarote",
    "Lounge Norte - Acesso 4 (N1/N2) e Acesso 20 (Oeste 2)": "Camarote",
    "Cadeira Sudeste Inferior - Acesso 3 (Sul)": "Cadeira Sudeste Inferior",
    "Cadeira Superior Leste - Acesso 12 (Leste)": "Cadeira Superior Leste",
    "Cadeira Especial Inferior - Acesso 3 (Sul)/Acesso 20 (Oeste 2)": "Cadeira Especial Inferior",
    "Cadeira Superior Norte - Acesso 19 (Superior Norte)": "Cadeira Superior Norte",
    "Cadeira Norte - Acesso 14 (Norte)": "Cadeira Norte",
    "Esquadrãozone Poltrona VIP": "Esquadrão Zone",
    "Lounge Museu - Acesso 20 (Oeste 2) e N3 Leste": "Camarote",
    "Camarotes Leste - Acesso 4 (N1/N2) Acesso 20 (Oeste 2)": "Camarote",
    "Camarotes Norte Mais - Acesso 4 (N1/N2) Acesso 20 (Oeste 2)": "Camarote",
    "Camarotes Mirante - Acesso 4 (N1/N2) Acesso 20 (Oeste 2)": "Camarote",
    "Lounge Exclusivo + Poltrona VIP": "Esquadrão Zone",
    "Cadeira Especial Superior - Acesso 3 (Sul)": "Cadeira Especial Superior",
    "Combo 1 adulto + 1 criança (até 12 anos)": "Camarote Kids",
    "Combo 2 adultos + 2 crianças (até 12 anos)": "Camarote Kids",
    "Combo 2 adultos + 1 criança (até 12 anos)": "Camarote Kids",
    "Combo 1 adulto + 2 crianças (até 12 anos)": "Camarote Kids",
    "Lounge Premium - Acesso 3 (Sul), Acesso 4 (N1/N2), Acesso 20 (Oeste 2)": "Lounge Premium",
    "Cadeira Leste - Acesso 3 (Sul)": "Cadeira Leste"
  }

  function processGroup(data, sectorMap) {
    const grouped = {};

    data.forEach(({ gate_id, count }) => {
      const key = sectorMap[gate_id] ?? gate_id;
      const numCount = parseInt(count, 10) || 0;

      grouped[key] = (grouped[key] || 0) + numCount;
    });

    return Object.entries(grouped).map(([gate_id, count]) => ({
      gate_id,
      count: Number(count)
    }));
  }

const getAllEntrances = () => {


  fetch(`${BASE.GRAPHY.url}${BASE.GRAPHY.getSectorListEntrances}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          Authorization: CONFIG.API_KEY,
          'x-api-key': CONFIG.API_KEY,
      }
  })
      .then((resg) => resg.json())
      .then((response) => {

        const result = {
          entrances_by_gate: processGroup(response .entrances_by_gate, mapSectors),
          tickets_by_gate: processGroup(response.tickets_by_gate, mapSectors)
        };

        const novosDados: DadosSetor[] = [];

        let gate_ref = false

        for(const sector_ticket of result.tickets_by_gate){

          for(const gate_entrances of result.entrances_by_gate){

            if(gate_entrances.gate_id === sector_ticket.gate_id){
              gate_ref = true
              novosDados.push({ setor: enumGate(sector_ticket.gate_id), pessoasEntraram: gate_entrances.count || 0, pessoasFaltam: ((sector_ticket.count || 0) - (gate_entrances.count || 0)), total: sector_ticket.count });
              continue
            }
          }

          if(!gate_ref){
            novosDados.push({ setor: enumGate(sector_ticket.gate_id), pessoasEntraram:  0, pessoasFaltam: sector_ticket.count || 0, total: sector_ticket.count });
          }
          gate_ref = false
          continue

        }

        // novosDados.push({ setor: 'BEPASS', pessoasEntraram: response.entrances[1] || 0, pessoasFaltam: (response.all[1] - response.entrances[1]), total: response.all[1] });
        // novosDados.push({ setor: 'SUP Norte', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        // novosDados.push({ setor: 'SUP LESTE', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        // novosDados.push({ setor: 'CADEIRA NORTE', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        // novosDados.push({ setor: 'CADEIRA LESTE', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        // novosDados.push({ setor: 'CADEIRA SUDESTE', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        // novosDados.push({ setor: 'ESPEC. INFERIOR', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        // novosDados.push({ setor: 'ESPEC. SUPERIOR', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        // novosDados.push({ setor: 'NAVE CWG', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        // novosDados.push({ setor: 'LOUGE PREMIUM', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        // novosDados.push({ setor: 'ESQUADRAO ZONE', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });

        setDados(novosDados);
            setTimeout(() => {
              getAllEntrances()
            }, 30000);

      })
      .catch((error) => {
          console.log(error)
            setTimeout(() => {
              getAllEntrances()
            }, 30000);
      })
}
  useEffect(() => {
    // const gerarDados = () => {

    // };

    getAllEntrances();

    // setInterval(
    //   getAllEntrances
    //   , 30000);

  }, []);


  const calcularTotalColuna = (propriedade: keyof DadosSetor): number => {
    return dados.reduce((total, item) => total + Number(item[propriedade]), 0);
  };

  return (
    <div>
      <table className='table table-bordered border-dark table-hover text-center game-table' style={{ fontSize: '0.6rem' }}>
        <thead>
          <tr>
            <th className='bg-dark bg-gradient text-white border-dark' style={{ width: '100px'}}>Setor</th>
            <th className='bg-dark bg-gradient text-white border-dark' style={{ width: '100px'}}>Entrou</th>
            <th className='bg-dark bg-gradient text-white border-dark' style={{ width: '100px'}}>Falta</th>
            <th className='bg-dark bg-gradient text-white border-dark' style={{ width: '100px'}}>Total</th>
          </tr>
        </thead>
        <tbody>
          {dados.map((item, index) => (
            <tr key={index}>
              <td className='text-white bg-success bg-gradient border-dark'>{item.setor}</td>
              <td className='text-white bg-success bg-gradient border-dark'>{item.pessoasEntraram}</td>
              <td className='text-white bg-success bg-gradient border-dark text-center'>{item.pessoasFaltam}</td>
              <td className='text-white bg-success bg-gradient border-dark text-center'>{item.total}</td>
            </tr>
          ))}

          <tr>
            <td className='bg-dark border-dark text-center'></td>
            <td className='bg-dark border-dark text-center'></td>
            <td className='bg-dark border-dark text-center'></td>
            <td className='bg-dark border-dark text-center'></td>
          </tr>

          <tr>
            <td className='bg-dark bg-gradient text-white border-dark text-center'>Total</td>
            <td className='bg-dark bg-gradient text-white border-dark text-center'>{calcularTotalColuna('pessoasEntraram')}</td>
            <td className='bg-dark bg-gradient text-white border-dark text-center'>{calcularTotalColuna('pessoasFaltam')}</td>
            <td className='bg-dark bg-gradient text-white border-dark text-center'>{calcularTotalColuna('total')}</td>
          </tr>
        </tbody>
      </table>
    </div>

  );
};

export default Table1;



